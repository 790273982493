<template>
  <div class="form-container">
    <h1 class="page-headline">Kontakt mig</h1>

    <iframe id="bookingFrame" :src="src" loading="lazy" scrolling="no" height="1300"
      style="width: 100%; max-width: 940px; border: 0; overflow: hidden;"></iframe>

  </div>
</template>

<script>
import FormInput from "@/components/FormInput.vue";
import henvendelseForm from "@/assets/json/henvendelse.json";

export default {
  name: "henvendelse",
  components: {
    FormInput,
  },
  props: {
    validation: {
      type: String,
      default: null,
    },
    firstname: {
      type: String,
      default: null,
    },
    lastname: {
      type: String,
      default: null,
    },
    mail: {
      type: String,
      default: null,
    },
    telephone: {
      type: String,
      default: null,
    },
    adresse: {
      type: String,
      default: null,
    },
    Street: {
      type: String,
      default: null,
    },
    StreetNumber: {
      type: String,
      default: null,
    },
    Place: {
      type: String,
      default: null,
    },
    City: {
      type: String,
      default: null,
    },
    PostalCode: {
      type: String,
      default: null,
    },
    Floor: {
      type: String,
      default: null,
    },
    Entrance: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      henvendelse: this.copy(henvendelseForm),
      openingDays: new Array(),
      busyDates: new Array(),
      numberOfDays: 6,
      loading: false,

      formUrl: process.env.VUE_APP_BOOK_URL,
    };
  },
  computed: {
    /*
     * Temporary product
     */
    temporaryProduct() {
      return this.$store.state.temporaryProduct;
    },
    /*
     * Basket from vuex store
     */
    basket() {
      return this.$store.state.basket;
    },
    /*
     * Basket from vuex store
     */
    coupon() {
      return this.$store.state.coupon;
    },
    /*
     * Source
     */
    src() {
      return this.formUrl + "/?url=" + location.protocol + "//" + location.host + location.pathname;
    },
    /*
     * Combined products and basket
     */
    combinedProducts() {
      let combinedProducts = this.copy(this.basket);
      let temporaryProduct = this.copy(this.temporaryProduct);

      if (temporaryProduct) {
        combinedProducts.unshift(temporaryProduct);
      }
      return combinedProducts;
    },
    /*
     * Customer age from products
     */
    age() {
      let age = null;

      // Loop through all products
      this.combinedProducts.forEach((product) => {
        if (product.fields.customerAge) {
          age = product.fields.customerAge.value;
        }
      });

      return age;
    },
    /*
     * Address from products
     */
    address() {
      let address = null;

      // Loop through all products
      this.combinedProducts.forEach((product) => {
        if (
          product.fields.customerAddress &&
          product.fields.customerAddress.selected
        ) {
          address = product.fields.customerAddress.selected;
        }
      });

      return address;
    },
    /*
     * Zipcode from products
     */
    zipcode() {
      let zipcode = null;

      this.combinedProducts.forEach((element) => {
        if (element.fields.zipcode && element.fields.zipcode.value) {
          zipcode = element.fields.zipcode.value;
        }
      });

      return zipcode;
    },
    /*
     * Check requirements
     */
    inquiryRequirementsMet() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.henvendelse.fields)) {
        if (value.required) {
          if (!value.value) {
            return false;
          }
        }
      }
      return true;
    },
  },
  created() {
    this.getOpeningDays();
    this.getBusyDates();

    window.addEventListener('message', ({ origin, data }) => {
      if (origin === this.formUrl && data.message === 'redirect') {
        this.$router.push({
          name: "Ordre",
          params: {
            henvendelse: {
              date: data.data.contactOn
            },
          },
        });
      };
    });
  },
  mounted() {
    // Post additional information to form iframe element
    const formElement = document.querySelector('#bookingFrame');

    formElement.onload = () => {
      const additionalFormPayload = {
        firstname: this.firstname,
        lastname: this.lastname,
        age: this.age,
        address: this.adresse != null ? this.adresse : this.address,
        zipcode: this.zipcode,
        basket: this.basket,
        combinedProducts: this.combinedProducts,
        coupon: this.coupon,
        temporaryProduct: this.temporaryProduct,
        productDescription: this.getProductDescription(this.combinedProducts),
        Street: this.Street,
        StreetNumber: this.StreetNumber,
        City: this.City,
        PostalCode: this.PostalCode,
        Floor: this.Floor,
        Entrance: this.Entrance,
      };

      // Insert first name if it is there
      if (this.firstname) {
        additionalFormPayload.firstname = this.firstname;
      }

      if (this.lastname) {
        additionalFormPayload.lastname = this.lastname;
      }

      // Insert mail if it is there
      if (this.mail) {
        additionalFormPayload.email = this.mail;
      }

      // Insert telephone if it is there
      if (this.telephone) {
        additionalFormPayload.phoneNumber = this.telephone;
      }

      formElement.contentWindow.postMessage(additionalFormPayload, this.formUrl);
    }
  },
  methods: {
    /*
     * Function for submitting henvendelse
     */
    submitHenvendelse() {
      let self = this;

      // If requirements arent met, break fom function
      if (!self.inquiryRequirementsMet) return;

      // Tell parent component that henvendelse is loading
      self.loading = true;

      // Get submit data
      let submitData = self.parseSubmitData();

      // Console log if in dev, test or beta environment
      if (
        process.env.NODE_ENV == "development" ||
        process.env.NODE_ENV == "test" ||
        process.env.NODE_ENV == "beta"
      ) {
        console.log(submitData);
      }

      // Create encrypted data
      let encryptedData = JSON.stringify(
        self.encrypt(JSON.stringify(submitData))
      );

      //Create api string
      let apiString = `/api/lead`;

      self
        .axios({
          method: "post",
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
          data: encryptedData,
        })
        .then(function (response) {
          self.loading = false;

          // Track data for retargeting
          self.trackData("createLead", self.temporaryProduct, self.basket);

          // Send to finish site
          self.$router.push({
            name: "Ordre",
            params: {
              henvendelse: {
                response: response,
                date: self.henvendelse.fields.date.selected.value,
                time: self.henvendelse.fields.time.selected.headline,
              },
            },
          });
        })
        .catch(function () {
          self.loading = false;
        });
    },
    /*
     * Translate submit data for henvendelse
     */
    parseSubmitData() {
      let self = this;

      // Insert data into submit data
      let submitData = {
        contactDate: self.henvendelse.fields.date.value,
        contactRange: self.henvendelse.fields.time.value,
        productDescription: self.getProductDescription(self.combinedProducts),
        validationMessage: self.validation ? self.validation : null,
        orderCompleted: false,
        voucher: self.coupon,
        customer: {
          isExisting: false,
          firstName: self.getFirstName(self.henvendelse.fields.name.value),
          lastName: self.getLastName(self.henvendelse.fields.name.value),
          telephoneMobile: self.henvendelse.fields.telephone.value,
          email: self.henvendelse.fields.mail.value,
          age: self.age,
        },
      };

      // If postal code is filled out
      if (self.zipcode) {
        submitData.customer.zipcode = self.zipcode;
      }

      // If address is filled
      if (self.address && self.address.data) {
        submitData.customer.fullAddress = self.address.tekst;
        submitData.customer.street = self.address.data.vejnavn;
        submitData.customer.streetNumber = self.address.data.husnr;
        submitData.customer.postalCode = self.address.data.postnr;
        submitData.customer.postalCity = self.address.data.postnrnavn;
        submitData.customer.floor = self.address.data.etage;
        submitData.customer.entrance = self.address.data.dør;
      }

      return submitData;
    },
    /*
     * Get next weekdays
     */
    getOpeningDays() {
      let self = this;

      // New opening days
      let openingDays = new Array();

      // Get todays date
      let today = self.dayjs();

      // Loop until we have set the number of days defined by variable
      for (let i = 0; openingDays.length < self.numberOfDays; i++) {
        let thisDate = self.dayjs(today).add(i, "day");

        if (thisDate.isoWeekday() < 6) {
          let dateObject = {
            key: thisDate,
            value: thisDate.format("YYYY-MM-DD"),
            headline: self.capitalizeFirstLetter(thisDate.calendar()),
            subline: thisDate.format("D. MMM, YYYY"),
          };
          openingDays.push(dateObject);
        }
      }

      // Override options
      self.henvendelse.fields.date.options = openingDays;
    },
    /*
     * Get next busydays
     */
    getBusyDates() {
      let self = this;

      // Create api string
      let apiString = `/api/calendar/busy/${self.numberOfDays}`;

      // Get options list
      self.axios
        .get(apiString, {
          params: {
            preventCache: self.dayjs().unix(),
          },
          headers: {
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
        })
        .then(function (response) {
          self.busyDates = response.data;
        });
    },
    /*
     * Get the opening hours for selected day
     */
    getOpeningHours(date) {
      // Get todays date
      let today = this.dayjs();

      // Hard copy date
      let currentDate = this.dayjs(this.copy(date));

      // Reset date milliseconds and seconds
      currentDate.millisecond(0);
      currentDate.second(0);
      currentDate.minute(30);

      // Standard opening hours on all days
      let openingHours = [
        {
          value: "08-10",
          headline: "08 - 10",
          cutoff: this.dayjs(this.copy(currentDate)).hour(9),
          disabled:
            today.isAfter(this.dayjs(this.copy(currentDate)).hour(9)) ||
            this.checkForAppointments(this.dayjs(this.copy(currentDate)).hour(9)),
        },
        {
          value: "10-12",
          headline: "10 - 12",
          cutoff: this.dayjs(this.copy(currentDate)).hour(11),
          disabled:
            today.isAfter(this.dayjs(this.copy(currentDate)).hour(11)) ||
            this.checkForAppointments(this.dayjs(this.copy(currentDate)).hour(11)),
        },
        {
          value: "12-14",
          headline: "12 - 14",
          cutoff: this.dayjs(this.copy(currentDate)).hour(13),
          disabled:
            today.isAfter(this.dayjs(this.copy(currentDate)).hour(13)) ||
            this.checkForAppointments(this.dayjs(this.copy(currentDate)).hour(13)),
        },
        {
          value: "14-16",
          headline: "14 - 16",
          cutoff: this.dayjs(this.copy(currentDate)).hour(15),
          disabled:
            today.isAfter(this.dayjs(this.copy(currentDate)).hour(15)) ||
            this.checkForAppointments(this.dayjs(this.copy(currentDate)).hour(15)),
        },
        {
          value: "16-18",
          headline: "16 - 18",
          cutoff: this.dayjs(this.copy(currentDate)).hour(17),
          disabled:
            date.isoWeekday() == 5 ||
            today.isAfter(this.dayjs(this.copy(currentDate)).hour(17)) ||
            this.checkForAppointments(this.dayjs(this.copy(currentDate)).hour(17)),
        },
        {
          value: "18-20",
          headline: "18 - 20",
          cutoff: this.dayjs(this.copy(currentDate)).hour(19),
          disabled:
            date.isoWeekday() == 5 ||
            today.isAfter(this.dayjs(this.copy(currentDate)).hour(19)) ||
            this.checkForAppointments(this.dayjs(this.copy(currentDate)).hour(19)),
        },
      ];

      // Return opening hours
      if (date.isoWeekday() < 6) {
        return openingHours;
      } else {
        return new Array();
      }
    },
    /*
     * Set opening hours based on choice of date
     */
    chooseDate() {
      // Delete selected time
      this.henvendelse.fields.time.value = null;

      if (this.henvendelse.fields.date.selected) {
        this.henvendelse.fields.time.options = this.getOpeningHours(this.henvendelse.fields.date.selected.key);
      } else {
        this.henvendelse.fields.time.options = [];
      }
    },
    /*
     * Check if holydays or appointments block
     */
    checkForAppointments(date) {
      let self = this;

      // Variable that defines whether the timeslot should be blocked
      let blockedTime = false;

      // Loop the busy dates array
      self.busyDates.forEach((busyDate) => {
        // If the time is between start time and end time return true
        if (date.isBetween(busyDate.startTime, busyDate.endTime)) {
          blockedTime = true;
        }
      });

      return blockedTime;
    },
  },
};
</script>
<style>
.form-container {
  text-align: center;
}
</style>